import React from "react";
import CourtPage from "./CourtPage";

export default function App() {
  return (
    <div>
      <CourtPage />
    </div>
  );
}
