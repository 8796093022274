import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import moment from "moment";
import { toast } from "react-toastify";

export default function CourtTable({ choosenCourt }) {
  const [settings, setSettings] = useState(null);
  const [sessionResponse, setSessionResponse] = useState(null);
  const [sessions, setSessions] = useState(null);
  const proxyUrl = "https://corsproxy.io/?";

  useEffect(() => {
    const settingUrl = `https://clubspark.lta.org.uk/v0/VenueBooking/${choosenCourt}/GetSettings`;
    fetch(proxyUrl + settingUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((response) => {
        setSettings(response);
      })
      .catch(() => {
        toast.error("Try again :(");
      });
  }, [choosenCourt]);

  useEffect(() => {
    if (!settings) return;

    const today = new Date();
    const startDate = moment(today).format("YYYY-MM-DD");

    const minuteOfTheDay = today.getHours() * 60 + today.getMinutes();
    const dayDelta =
      minuteOfTheDay >= settings.NewDayBookingAvailabilityTime ? 7 : 6;
    const lastDay = new Date();
    lastDay.setDate(lastDay.getDate() + dayDelta);
    const endDate = moment(lastDay).format("YYYY-MM-DD");

    const sessionUrl = `https://clubspark.lta.org.uk/v0/VenueBooking/${choosenCourt}/GetVenueSessions?startDate=${startDate}&endDate=${endDate}`;
    fetch(proxyUrl + sessionUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((response) => {
        setSessionResponse(response);
      })
      .catch((error) => {
        toast.error("Try again :(");
      });
  }, [settings, choosenCourt]);

  useEffect(() => {
    if (!sessionResponse) return;

    const data = {
      startTime: sessionResponse.EarliestStartTime / 60,
      endTime: sessionResponse.LatestEndTime / 60,
      days: {},
    };

    for (const day of sessionResponse.Resources[0].Days) {
      const date_str = day.Date.substring(0, 10);
      data.days[date_str] = {
        url: `https://clubspark.lta.org.uk/${choosenCourt}/Booking/BookByDate#?date=${date_str}`,
        slots: {},
      };
      for (let j = data.startTime; j < data.endTime; j++) {
        data.days[date_str].slots[j] = {
          count: 0,
          price: "",
        };
      }
    }

    for (const resource of sessionResponse.Resources) {
      const keywords = ["Kids", "Mini"];
      if (keywords.some((keyword) => resource.Name.includes(keyword))) {
        continue;
      }

      for (const day of resource.Days) {
        for (const session of day.Sessions) {
          if (!session.Capacity) {
            continue;
          }

          const date_str = day.Date.substring(0, 10);
          const time = session.StartTime / 60;
          const sessionCount = (session.EndTime - session.StartTime) / 60;
          for (let i = 0; i < sessionCount; i++) {
            data.days[date_str].slots[time + i].count++;
            data.days[date_str].slots[time + i].price = session.Cost;
          }
        }
      }
    }

    setSessions(data);
  }, [sessionResponse, choosenCourt]);

  const formatTime = (hour) => {
    return `${hour < 10 ? "0" : ""}${hour}:00`;
  };

  const formatDate = (date) => {
    const parsedDate = parseISO(date);
    const dayOfMonth = parseInt(format(parsedDate, "d"), 10);
    let suffix = "th";
    if ([1, 11, 21, 31].includes(dayOfMonth)) {
      suffix = "st";
    } else if ([2, 12, 22].includes(dayOfMonth)) {
      suffix = "nd";
    } else if ([3, 13, 23].includes(dayOfMonth)) {
      suffix = "rd";
    }

    const dayWithSuffix = `${dayOfMonth}${suffix}`;
    const dayName = format(parsedDate, "EEE");
    const monthName = format(parsedDate, "MMMM");
    return `${dayName} ${dayWithSuffix} ${monthName}`;
  };

  const formatPrice = (price) => {
    return price ? `£${price.toFixed(2)}` : "Free";
  };

  return sessions ? (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th></th>
          {Object.entries(sessions.days).map(([date, day]) => (
            <th key={date} style={{ "font-size": "14px" }}>
              {formatDate(date)}
              <br />
              <a href={day.url} target="_blank" rel="noreferrer">
                Book
              </a>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from(
          { length: sessions.endTime - sessions.startTime },
          (_, i) => i + sessions.startTime
        ).map((time) => (
          <tr key={time}>
            <td>{formatTime(time)}</td>
            {Object.entries(sessions.days).map(([date, day]) => (
              <td
                key={date}
                className={
                  day.slots[time].count ? "bg-success text-center" : "bg-danger"
                }
                style={{ "font-size": "15px" }}
              >
                {day.slots[time].count
                  ? `${day.slots[time].count} - ${formatPrice(
                      day.slots[time].price
                    )}`
                  : ""}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    "Loading..."
  );
}
