import React, { useState } from "react";
import CourtList from "./CourtList";
import CourtTable from "./CourtTable";

export default function CourtPage() {
  const [choosenCourt, setChoosenCourt] = useState(null);

  return (
    <div className="conatiner-fluid">
      <div className="row">
        <div className="col-md-3 pr-0" style={{ backgroundColor: "#eaeaee" }}>
          <div className="pt-5 px-3">
            <CourtList
              choosenCourt={choosenCourt}
              setChoosenCourt={setChoosenCourt}
            />
          </div>
        </div>
        <div className="col-md-9">
          <div className="vh-100 p-sm-5 p-3">
            <div className="h-100 overflow-auto">
              {choosenCourt ? (
                <CourtTable choosenCourt={choosenCourt} />
              ) : (
                "Please select a court"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
