import React from "react";

export default function CourtList({ choosenCourt, setChoosenCourt }) {
  const courtNames = [
    "FinsburyPark",
    "HackneyDowns",
    "ClissoldParkHackney",
    "LondonFieldsPark",
    "SpringHillParkTennis",
    "MillfieldsParkMiddlesex",
    "AskeGardens",
    "RedhillPark",
    "SwanmoreGardensTennisCourts",
    "ShelleyPark",
    "WintonRecreationTennisCourts",
  ];

  const getButtons = () => {
    return courtNames.map((courtName) => (
      <div className="pb-3">
        <button
          key={courtName}
          type="button"
          className={`w-100 btn rounded-0 ${
            courtName === choosenCourt ? "bg-secondary" : "bg-light"
          }`}
          onClick={() => setChoosenCourt(courtName)}
        >
          {courtName}
        </button>
      </div>
    ));
  };
  return <div>{getButtons()}</div>;
}
